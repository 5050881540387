<template>
  <el-dialog
    title="批量打印PDF"
    :visible.sync="showPdf"
    width="580px"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="closeDialogs"
    v-dialogDrag
    v-disable-contextmenu
  >
    <div class="dialog-body-box">
      <div
        class="cad-select_wrapper"
        v-stopdrag
        style="max-height: 500px; min-height: 325px; overflow-y: auto"
      >
        <div class="block-wrapper" style="padding: 0 32px" v-closeSelect>
          <div class="cad-select_wrapper" style="overflow: hidden">
            <input
              ref="filePdf"
              type="file"
              style="display: none"
              @change="uploadPdf"
              accept=".ctb"
            />
            <div
              class="flex align-start text-item spray-input-box"
              style="padding: 16px 0 0 0"
            >
              <div classs="block-title" style="width: 100px">选择打印样式:</div>
              <div class="spray-input">
                <el-select
                  style="width: 100%"
                  v-model="selectPdf"
                  placeholder="请选择"
                  ref="selectPdf0"
                >
                  <el-option
                    v-for="(item, idx) in pdfList"
                    :key="idx"
                    :label="item.name"
                    :value="item.styleName"
                  >
                  </el-option>
                </el-select>
              </div>
              <div
                class="flex"
                style="justify-content: flex-end; margin-left: 10px"
              >
                <el-button @click="submitPdf" type="primary" size="small">
                  上传样式</el-button
                >
              </div>
            </div>

            <div
              class="flex align-start text-item spray-input-box"
              style="padding: 16px 0 0 0"
            >
              <div classs="block-title" style="width: 100px">打印图框图层:</div>
              <div class="spray-input">
                <el-select
                  v-model="selectPdfLayer"
                  placeholder="请选择"
                  style="width: 100%"
                  filterable
                  ref="selectPdf1"
                >
                  <el-option
                    v-for="(item, idx) in pdfLayerList"
                    :key="idx"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
              <div
                class="flex"
                style="justify-content: flex-end; margin-left: 10px"
              >
                <el-button
                  @click="selectLayerbyData"
                  type="primary"
                  size="small"
                >
                  从图面选择</el-button
                >
              </div>
            </div>

            <div
              class="flex align-start text-item spray-input-box"
              style="padding: 16px 0 0 0"
            >
              <div classs="block-title" style="width: 100px">显示图纸范围:</div>
              <div class="spray-input">
                <el-checkbox v-model="isFullPage"></el-checkbox>
              </div>
            </div>

            <div
              class="flex align-start text-item spray-input-box"
              style="padding: 16px 0 0 0"
              v-if="isFullPage"
            >
              <div classs="block-title" style="width: 100px">选择纸张类型:</div>
              <div class="spray-input">
                <el-select
                  v-model="selectPdfStyle"
                  placeholder="请选择"
                  style="width: 100%"
                  ref="selectPdf2"
                  @change="val => changePdfStyle(val)"
                  @visible-change="val => visbleChange(val)"
                >
                  <el-option
                    v-for="(item, idx) in pdfStyleList"
                    :key="idx"
                    :label="item.name"
                    :value="item.name"
                  >
                    <div class="pdf-tyoe-item">
                      <div class="pdf-type">{{ item.name }}</div>
                      <div
                        class="pdf-del"
                        v-if="item.isDel"
                        @click.stop="delPdf(item, idx)"
                      >
                        <i class="el-icon-close"></i>
                      </div>
                    </div>
                  </el-option>
                </el-select>
              </div>
            </div>

            <div
              class="flex align-start text-item spray-input-box"
              style="padding: 16px 0 0 0"
              v-if="isFullPage"
            >
              <div classs="block-title" style="width: 100px">页边距设置:</div>
              <div class="spray-input">
                <div class="bj-input-box">
                  <el-input
                    v-model="Margin.top"
                    v-int-input
                    placeholder="上边距"
                  >
                    <span slot="prefix">上</span>
                    <span slot="suffix">mm</span>
                  </el-input>
                  <el-input
                    v-model="Margin.bottom"
                    v-int-input
                    placeholder="下边距"
                  >
                    <span slot="prefix">下</span>
                    <span slot="suffix">mm</span>
                  </el-input>
                </div>
                <div class="bj-input-box" style="margin-top: 10px">
                  <el-input
                    v-model="Margin.left"
                    placeholder="左边距"
                    v-int-input
                  >
                    <span slot="prefix">左</span>
                    <span slot="suffix">mm</span>
                  </el-input>
                  <el-input
                    v-model="Margin.right"
                    placeholder="右边距"
                    v-int-input
                  >
                    <span slot="prefix">右</span>
                    <span slot="suffix">mm</span>
                  </el-input>
                </div>
              </div>
            </div>
            <div
              class="flex align-start text-item spray-input-box"
              style="padding: 16px 0 0 0"
            >
              <div classs="block-title" style="width: 100px">选择图纸数据:</div>
              <div class="spray-input" style="flex: 0 0 100px !important">
                <el-button type="primary" @click="selectSingle">点选</el-button>
              </div>
              <div class="spray-input" style="flex: 0 0 100px !important">
                <el-button type="primary" @click="selectRange">框选</el-button>
              </div>
            </div>
            <div
              class="flex align-start text-item spray-input-box"
              style="padding: 16px 0 0 0"
            >
              <div class="flex" style="justify-content: flex-start">
                <el-table :data="box_list" style="width: 100%" height="100%">
                  <el-table-column
                    prop="index"
                    align="center"
                    label="序号"
                    width="50"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="frameName"
                    align="center"
                    label="图名"
                    width="200"
                  >
                    <template slot="header" slot-scope="{ column }">
                      <el-tooltip
                        :content="getTooltipContent(selectFrameNameLayerName)"
                        placement="top"
                        effect="dark"
                      >
                        <el-button type="text" @click="selectFrameNameLayer">{{
                          column.label
                        }}</el-button>
                      </el-tooltip>
                    </template>

                    <template slot-scope="scope">
                      <el-input v-model="scope.row.frameName" type="string" />
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="frameNo"
                    align="center"
                    label="图号"
                    width="200"
                  >
                    <template slot="header" slot-scope="{ column }">
                      <el-tooltip
                        :content="getTooltipContent(selectFrameNoLayerName)"
                        placement="top"
                        effect="dark"
                      >
                        <el-button type="text" @click="selectFrameNoLayer">{{
                          column.label
                        }}</el-button>
                      </el-tooltip>
                    </template>

                    <template slot-scope="scope">
                      <el-input v-model="scope.row.frameNo" type="string" />
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div
              class="flex align-start text-item spray-input-box"
              style="padding: 16px 0 16px 0"
            >
              <div classs="block-title" style="width: 100px">打印类型:</div>
              <div class="spray-input">
                <el-radio-group v-model="printType">
                  <el-radio border label="pdf">单张PDF</el-radio>
                  <el-radio border label="zip">多张PDF</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="surePdf" type="primary" size="nomal">确定</el-button>
      <el-button @click="closeDialogs()" size="nomal" native-type="button"
        >取消</el-button
      >
    </div>
    <el-dialog
      title="自定义纸张"
      :visible.sync="showZz"
      width="550px"
      :append-to-body="true"
      :close-on-click-modal="false"
      @close="showZz = false"
      v-dialogDrag
      v-disable-contextmenu
    >
      <div
        class="cad-select_wrapper"
        v-stopdrag
        style="max-height: 500px; overflow-y: auto"
      >
        <div class="block-wrapper" style="padding: 0 32px">
          <div class="cad-select_wrapper" style="overflow: hidden">
            <div
              class="flex align-start text-item spray-input-box"
              style="padding: 16px 0 0 0"
            >
              <div classs="block-title" style="width: 100px">自定义名字:</div>
              <div class="spray-input" style="flex: 1 !important;">
                <el-input v-model="zdyTitle" placeholder="名字"> </el-input>
              </div>
            </div>
            <div
              class="flex align-start text-item spray-input-box"
              style="padding: 16px 0 0 0"
            >
              <div classs="block-title" style="width: 100px">纸张宽度:</div>
              <div class="spray-input" style="flex: 1 !important;">
                <el-input v-model="zdyWidth" v-int-input placeholder="宽度">
                  <span slot="suffix">mm</span>
                </el-input>
              </div>
            </div>
            <div
              class="flex align-start text-item spray-input-box"
              style="padding: 16px 0 0 0"
            >
              <div classs="block-title" style="width: 100px">纸张高度:</div>
              <div class="spray-input" style="flex: 1 !important;">
                <el-input v-model="zdyHeight" v-int-input placeholder="宽度">
                  <span slot="suffix">mm</span>
                </el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="sureZdyZz" type="primary" size="nomal"
          >确定</el-button
        >
        <el-button @click="showZz = false" size="nomal" native-type="button"
          >取消</el-button
        >
      </div>
    </el-dialog>
  </el-dialog>
</template>
<script>
import { post } from "@/api/index";
import { CommandFactory, CommandStatus } from "@/cadplugins/map/commandfactory";
import { DrawUtilImpl } from "@/cadplugins/tools/drawutils";
import { LTPoint, RBox } from "@/cadplugins/cad/ltpoint";
import { LTUtils, MapCursorType } from "@/cadplugins/cad/ltutils";
import { LTText, LTEntityPoint } from "@/cadplugins/cad/ltentity";
import { SelectType } from "@/cadplugins/tools/selectcommands";

import { SaveCommandImpl } from "@/cadplugins/tools/savecommands";
import { dialogMixin } from "@/utils/dialogMixin";
export default {
  props: {
    supThis: {
      type: Object
    }
  },
  mixins: [dialogMixin],
  data() {
    return {
      // pdf下载
      showPdf: false,
      selectPdf: "",
      pdfWidth: "",
      pdfHeight: "",
      pdfList: [],
      selectPdfStyle: "",
      isFullPage: false, //是否显示图纸范围
      Margin: {
        isShow: false,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      },
      box_list: [],
      pdfStyleList: [
        {
          name: "A0(1189X841)",
          width: 1189,
          height: 841,
          isDel: false
        },
        {
          name: "A1(841X594)",
          width: 841,
          height: 594,
          isDel: false
        },
        {
          name: "A2(594X420)",
          width: 594,
          height: 420,
          isDel: false
        },
        {
          name: "A3(420X297)",
          width: 420,
          height: 297,
          isDel: false
        },
        {
          name: "A4(297X210)",
          width: 297,
          height: 210,
          isDel: false
        },
        {
          name: "自定义",
          width: 0,
          height: 0,
          isDel: false
        }
      ],
      printType: "pdf",
      pdfLayerList: [],
      selectPdfLayer: "",
      selectLayerBlockName: "",
      selectFrameNameLayerName: "",
      selectFrameNoLayerName: "",
      // 自定义纸张
      showZz: false,
      zdyTitle: "",
      zdyWidth: 0,
      zdyHeight: 0
    };
  },
  created() {},

  select_frames: [], //ltfeature列表,不能存到data中,会造成效率问题
  methods: {
    getPdfList() {
      return new Promise((resolve, reject) => {
        this.pdfList = [];
        post(this.$setApi("/cus/pdf/style/page"), {
          pageNum: 0,
          pageSize: 1000
        }).then(res => {
          if (
            res &&
            res.data &&
            res.data.records &&
            res.data.records.length > 0
          ) {
            res.data.records.map(item => {
              if (item.styleName === undefined || item.styleName === null) {
                return;
              }
              item.name = JSON.parse(JSON.stringify(item.styleName));
              item.name = item.name.replace(".ctb", "");
              this.pdfList.push(item);
            });
          }
          resolve();
        });
      });
    },
    submitPdf() {
      this.$refs.filePdf.click();
    },
    getTooltipContent(name) {
      if (name == "") {
        return "点击通过图面数据所在图层识别";
      }
      return `当前识别的图层名:${name}`;
    },
    // 删除自定义纸张
    delPdf(item, idx) {
      //itm删除对象,idx删除下标
      let item_name = item.name;
      this.pdfStyleList.splice(idx, 1);
      if (this.selectPdfStyle == item_name && this.pdfStyleList.length > 0) {
        this.selectPdfStyle = this.pdfStyleList[0].name;
      }
    },
    changePdfStyle(val) {
      if (val == "自定义") {
        //弹出自定义纸张界面;
        this.showZz = true;
      } else {
        this.showZz = false;
        this.pdfStyleList.some(item => {
          if (item.name == val) {
            this.pdfWidth = item.width;
            this.pdfHeight = item.height;
            return true;
          }
        });
      }
    },
    visbleChange(val) {
      if (!val) {
        if (this.selectPdfStyle.indexOf("自定义") > -1) {
          this.showZz = true;
        }
      }
    },

    uploadPdf(event) {
      // 文件上传之后的回调
      const fileList = event.target.files || event.dataTransfer.files; // 上传的文件数组
      const fileData = fileList[0];
      console.log("uploadPdf", fileData);
      if (fileData && fileData.name && fileData.name.indexOf(".ctb") > -1) {
        var postData = new FormData();
        postData.append("file", fileData);
        post(this.$setApi("/cus/pdf/style"), postData).then(res => {
          if (res) {
            this.$message.success("新增成功");
            this.getPdfList();
            this.$refs.filePdf.value = null; // 移除文件，可以保证上传同样的文件时，也会触发change事件
          }
        });
      } else this.$message.warning("请上传ctb格式文件");
    },
    init(map) {
      this.getPdfList().then(() => {
        //获取自定义列表;
        this.supThis.getUserSettingData().then(res => {
          if (res && res.custompdf) {
            this.pdfStyleList = JSON.parse(res.custompdf);
          }
        });
        //获取图层列表;
        this.pdfLayerList = [];
        this.box_list = [];
        map.getLayers().forEach(layer => {
          this.pdfLayerList.push(layer.name);
        });
        if (this.pdfList.length == 0) return;
        this.showPdf = true;
        this.selectPdf = "";
        if (this.pdfList.length > 0) this.selectPdf = this.pdfList[0].styleName;
      });
    },

    closeDialogs() {
      this.showPdf = false;
      this.supThis.pixiMap.clearTrackGraphics();
      this.closeDialog();
    },
    formatDateTime(date) {
      const year = date
        .getFullYear()
        .toString()
        .padStart(4, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date
        .getDate()
        .toString()
        .padStart(2, "0");
      const hours = date
        .getHours()
        .toString()
        .padStart(2, "0");
      const minutes = date
        .getMinutes()
        .toString()
        .padStart(2, "0");
      const seconds = date
        .getSeconds()
        .toString()
        .padStart(2, "0");

      return `${year}-${month}-${day}-${hours}-${minutes}`;
    },

    // 确定pdf
    surePdf() {
      this.showPdf = false;

      if (this.select_frames.length == 0) {
        this.$message.warning("请选择图框");
        return;
      }
      this.supThis.pixiMap.clearTrackGraphics();
      let temp_frames = [];

      for (let i = 0; i < this.select_frames.length; i++) {
        let box_info = this.box_list[i];
        let str_name = `${box_info.index}_${box_info.frameNo}_${
          box_info.frameName
        }_${this.formatDateTime(new Date())}.pdf`;
        let temp_feature = this.select_frames[i];
        temp_frames.push({ name: str_name, feature: temp_feature });
      }
      let param_map = {
        style_name: this.selectPdf,
        frames: temp_frames
      };
      if (this.isFullPage) {
        param_map.page = {
          width: this.pdfWidth,
          height: this.pdfHeight
        };
        param_map.margin = this.Margin;
      }
      if (this.printType == "zip") {
        param_map.zip = true;
      } else {
        param_map.zip = false;
      }
      this.supThis
        .saveUserSettingData("custompdf", JSON.stringify(this.pdfStyleList))
        .then(res => {
          SaveCommandImpl.save(this.supThis.pixiMap, false);
          CommandFactory.getInstance().execCommand(
            "exportPDF",
            this.supThis.pixiMap,
            param_map
          );
        });
    },
    // 自定义纸张
    sureZdyZz () {
      if(this.zdyTitle == "")
      {
        this.$message.warning("请输入自定义纸张名称");
        return;
      }
      
      if(this.zdyWidth == 0 || this.zdyHeight == 0)
      {
        this.$message.warning("请输入纸张尺寸");
        return;
      }

      this.oldWidth = JSON.parse(JSON.stringify(this.zdyWidth));
      this.oldHeight = JSON.parse(JSON.stringify(this.zdyHeight));
   

      let zdy_name = `${this.zdyTitle}(${this.zdyWidth}X${this.zdyHeight})`;
      this.pdfStyleList.splice(this.pdfStyleList.length - 1, 0, {
        name: zdy_name,
        width: this.zdyWidth,
        height: this.zdyHeight,
        isDel: true
      });

      this.pdfWidth = this.zdyWidth;
      this.pdfHeight = this.zdyHeight;
      this.selectPdfStyle = zdy_name;

      // 关闭自定义对话框;
      this.showZz = false;
    },
    __geoType__(geometry) {
      return Object.prototype.toString.call(geometry).slice(8, -1);
    },

    selectFrameNameLayer() {
      this.showPdf = false;
      let parma_map = {
        callback: layer => {
          this.selectFrameNameLayerName = layer;
          if (this.box_list.length > 0) {
            for (let temp_box of this.box_list) {
              temp_box.frameName = this.getFrameName(
                this.supThis.pixiMap,
                temp_box.box
              );
            }
          } else {
            this.$message.warning("未选择图框");
          }
        }
      };
      if (this.box_list.length == 0) {
        this.$message.warning("未选择图框");
        return;
      }
      CommandFactory.getInstance().execCommandFun(
        "selectLayer",
        this.selectLayer,
        this.supThis.pixiMap,
        parma_map
      );
    },

    selectFrameNoLayer() {
      this.showPdf = false;
      let parma_map = {
        callback: layer_name => {
          this.selectFrameNoLayerName = layer_name;
          for (let temp_box of this.box_list) {
            temp_box.frameNo = this.getFrameNoByBox(
              this.supThis.pixiMap,
              temp_box.box
            );
          }
        }
      };
      if (this.box_list.length == 0) {
        this.$message.warning("未选择图框");
        return;
      }
      CommandFactory.getInstance().execCommandFun(
        "selectLayer",
        this.selectLayer,
        this.supThis.pixiMap,
        parma_map
      );
    },
    getFrameNo(map, geo) {
      let result_entity = null;
      if (this.__geoType__(geo) === "LTEntityPoint") {
        let block_reference = cadmap.dwgservice.getBlock(geo.blockName);
        if (block_reference == null) {
          return "";
        }

        const selectCallback = sub_geo => {
          if (!(sub_geo instanceof LTText)) {
            return false;
          }
          if (this.selectFrameNoLayerName !== null) {
            if (sub_geo.getLayerName() == this.selectFrameNoLayerName) {
              return true;
            }
          }
          let sub_text = sub_geo.text;
          let index = sub_text.indexOf("号");
          if (index >= 0 && index == sub_text.length - 1) {
            if (sub_text.indexOf("图") >= 0) return true;
          }
          return false;
        };

        let sub_entities = block_reference
          .spatialIndex()
          .getEntitys2(geo, geo.getBoundingBox(), selectCallback);
        if (sub_entities.length == 0) {
          return "";
        }
        result_entity = sub_entities[0];
      } else {
        function selectCallback(fea) {
          let sub_geo = fea.getGeometry();
          if (!(sub_geo instanceof LTText)) {
            return false;
          }
          let sub_text = sub_geo.text;
          let index = sub_text.indexOf("号");
          if (index >= 0 && index == sub_text.length - 2) {
            return true;
          }
          return false;
        }

        let result_features = map.selectFeatures(
          geo.getBoundingBox(),
          selectCallback
        );
        if (result_features.length == 0) {
          return "";
        }
        result_entity = result_features[0].getGeometry();
      }
      if (result_entity === null) {
        return "";
      }
      let box = result_entity.getBoundingBox();
      let block_box = geo.getBoundingBox();
      let c1 = new LTPoint(box.getMaximum().x, box.getMinimum().y);
      let c2 = new LTPoint(block_box.getMaximum().x, box.getMaximum().y);
      box = RBox.formVectors(c1, c2);

      function selectCallback2(fea) {
        let sub_geo = fea.getGeometry();
        if (!(sub_geo instanceof LTText)) {
          return false;
        }
        let sub_text = sub_geo.text;
        return true;
      }
      let result_features = map.selectFeatures(box, selectCallback2);
      if (result_features.length == 0) {
        return "";
      }
      let text = result_features[0].getGeometry().text;
      return text;
    },
    getFrameNoByBox(map, box) {
      const selectCallback2 = fea => {
        let sub_geo = fea.getGeometry();
        if (!(sub_geo instanceof LTText)) {
          return false;
        }
        if (this.selectFrameNoLayerName !== null) {
          if (sub_geo.getLayerName() == this.selectFrameNoLayerName) {
            return true;
          }
        }
        return false;
      };
      let result_features = map.selectFeatures(box, selectCallback2);
      if (result_features.length == 0) {
        return "";
      }
      let text = result_features[0].getGeometry().text;
      return text;
    },
    getFrameName(map, box) {
      function isEndingWithSpecificWords(str) {
        const endings = ["平面", "平面图", "原理图", "原理"];
        let isEndingWithSpecificWord = false;

        endings.forEach(ending => {
          if (str.endsWith(ending)) {
            isEndingWithSpecificWord = true;
            // 一旦找到匹配的结尾，就不需要继续循环了
            return false;
          }
          return true; // 继续循环
        });

        return isEndingWithSpecificWord;
      }

      const selectCallback = fea => {
        let sub_geo = fea.getGeometry();
        if (!(sub_geo instanceof LTText)) {
          return false;
        }
        if (this.selectFrameNameLayerName !== null) {
          if (sub_geo.getLayerName() == this.selectFrameNameLayerName) {
            return true;
          }
        }
        let sub_text = sub_geo.text;
        if (isEndingWithSpecificWords(sub_text)) {
          return true;
        }
        return false;
      };

      let result_features = map.selectFeatures(box, selectCallback);
      if (result_features.length == 0) {
        return "";
      }
      let sub_text = result_features[0].getGeometry().text;
      return sub_text;
    },
    select1: function*(map, params) {
      //正常情况下为查找选择某个图层下的数据;
      let result_features = [];
      let index = 0;
      const sort_feature = (a, b) => {
        let a1 = a.getGeometry().getBoundingBox();
        let a2 = b.getGeometry().getBoundingBox();
        let left1 = new LTPoint(a1.getMinimum().x, a1.getMaximum().y);
        let left2 = new LTPoint(a2.getMinimum().x, a2.getMaximum().y);

        if (left1.x < left2.x) {
          if (left1.y + a1.getHeight() < left2.y) {
            return 1;
          }
          return -1;
        } else {
          if (left2.y + a2.getHeight() < left1.y) {
            return -1;
          }
          return 1;
        }
      };
      const _renderCallback = feature => {
        index += 1;
        let geometry = feature.getGeometry();
        let bbox = geometry.getBoundingBox();
        let text = LTUtils.drawCenterText(
          index.toString(),
          bbox.getHeight() / 2,
          bbox
        );
        text.setColor("0xFF0000");
        text.subData().fontName = "汉仪中等线";

        DrawUtilImpl.trackEntity(this.supThis.pixiMap, text);

        this.box_list.push({
          index: index,
          frameName: this.getFrameName(this.supThis.pixiMap, bbox),
          frameNo: this.getFrameNo(this.supThis.pixiMap, geometry),
          box: bbox
        });
      };

      params.renderCallback = feature => {
        if (feature instanceof Array) {
          //排序;
          let selectfeatures = feature.sort(sort_feature);
          for (let i = 0; i < selectfeatures.length; i++) {
            _renderCallback(selectfeatures[i]);
          }
          result_features.push(...selectfeatures);
        } else {
          _renderCallback(feature);
          result_features.push(feature);
        }
      };

      params.callback = feature => {
        let geometry = feature.getGeometry();
        if (geometry instanceof LTEntityPoint) {
          if(params.block_name.length > 0)
          {
            if(geometry.blockName == params.block_name)
            {
              return true;
            }
            return false;
          }
          return true;
        }
        return false;
      };

      CommandFactory.getInstance().execYieldCommand("select", map, params);
      let select_feature = yield;
      if (select_feature.done == CommandStatus.eCancel) {
        return false;
      }

      if (result_features.length == 0) {
        this.showPdf = true;
        return false;
      }
      for (let sub_feature of result_features) {
        if (this.__geoType__(sub_feature.getGeometry()) !== "LTEntityPoint") {
          this.$message("请确保选择的图形是图框");
          return false;
        }
      }

      this.select_frames = result_features;
      this.showPdf = true;
      return true;
    },

    selectLayer: function*(map, params) {
      let target_feature = null;
      while (true) {
        map.cursorType = MapCursorType.CursorSingleSelect;
        CommandFactory.getInstance().getPoint(
          map,
          "请点击选择对象来获取该对象所在图层,右键结束选择:"
        );
        let get_layer_point = yield;
        if (
          get_layer_point.done == CommandStatus.eCancel ||
          get_layer_point.done == CommandStatus.eDone
        ) {
          break;
        }
        let feature = this.supThis.pixiMap.selectSingleFeature(
          get_layer_point.value
        );
        if (feature == null) {
          this.$message.warning("未选择有效对象");
          continue;
        }
        this.supThis.pixiMap.clearSelection();
        this.supThis.pixiMap.select_command.selectFeatures([feature]);
        target_feature = feature;
      }

      if (target_feature !== null) {
        let layer_name = target_feature.layer.name;
        let layer_blockname="";
        if(target_feature.getGeometry() instanceof LTEntityPoint)
        {
          layer_blockname=target_feature.getGeometry().blockName;
        }
        if (params.callback !== null) {
          params.callback(layer_name,layer_blockname);
        }
      }

      this.showPdf = true;
    },
    selectLayerbyData() {
      this.showPdf = false;
      this.showPdf = false;
      let parma_map = {
        callback: (layer,layer_blockname) => {
          this.selectPdfLayer = layer;
          this.selectLayerBlockName = layer_blockname;
        }
      };
      CommandFactory.getInstance().execCommandFun(
        "selectLayer",
        this.selectLayer,
        this.supThis.pixiMap,
        parma_map
      );
    },
    //选择图纸范围
    selectSingle() {
      if (this.selectPdfLayer.length === 0) {
        this.$message.warning("请先选择打印图框图层。");
        return;
      }
      this.supThis.pixiMap.clearTrackGraphics();
      this.showPdf = false;
      let param_map = {
        layerfilter: [this.selectPdfLayer],
        select_type: SelectType.eSelectPoint,
        printSelectSingle: true,
        continue_select: true,
        block_name:this.selectLayerBlockName
      };
      this.box_list = [];
      CommandFactory.getInstance().execCommandFun(
        "select1",
        this.select1,
        this.supThis.pixiMap,
        param_map
      );
    },

    //范围选择;
    selectRange() {
      if (this.selectPdfLayer.length === 0) {
        this.$message.warning("请先选择打印图框图层。");
        return;
      }
      this.supThis.pixiMap.clearTrackGraphics();
      this.showPdf = false;
      let param_map = {
        layerfilter: [this.selectPdfLayer],
        selecttype: SelectType.eSelectBox,
        printSelectSingle: false,
        continue_select: true,
        block_name:this.selectLayerBlockName
      };
      this.box_list = [];
      CommandFactory.getInstance().execCommandFun(
        "selectrange",
        this.select1,
        this.supThis.pixiMap,
        param_map
      );
    }
  }
};
</script>
<style lang="stylus" scoped>
@import '~@/assets/styl/cad'

>>>.el-input__prefix, >>>.el-input__suffix
  line-height 32px

.bj-input-box
  display flex
  justify-content space-between

  >>>.el-input
    width 45%

.spray-input
  flex 0 0 250px !important

  &:first-child
    flex 1 !important
.pdf-tyoe-item
  display flex
  align-items center

  .pdf-type
    flex 1
</style>
